<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateActivity)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <activities-form
          :activity-data="activityData"
        />

      </b-form>

    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import ActivitiesForm from '../activities-form/ActivitiesForm.vue'
import activityStoreModule from '../activityStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    ActivitiesForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const ACTIVITY_APP_STORE_MODULE_NAME = 'app-activity'

    // Register module
    if (!store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_APP_STORE_MODULE_NAME)
    })

    const activityData = ref({})

    async function fetchActivity() {
      try {
        const response = await store.dispatch('app-activity/fetchActivity', router.currentRoute.params.id)
        // eslint-disable-next-line prefer-destructuring
        activityData.value = response.data.data[0]
        activityData.value.isSubActivity = false
        activityData.value.activityId = router.currentRoute.params.id
      } catch (error) {
        console.error(error)
      }
    }

    fetchActivity()

    async function updateActivity() {
      try {
        const response = await store.dispatch('app-activity/updateActivity', activityData.value)

        if (response.data.status) {
          router.push({ name: 'general-settings-activities-list' })
        }
      } catch (error) {
        console.error(error)
      }
    }

    return {
      //  Data
      activityData,

      //  Action
      updateActivity,
    }
  },
}
</script>
